.btn-green {
  color: #f1f1f1;
}[dir] .btn-green {
  background: #00c390;
}
.btn-green:hover {
  color: #fff;
}
.modal-installments .modal-dialog {
  max-width: 950px !important;
}
.modal-search-customer .modal-dialog {
  max-width: 650px !important;
}